<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.report">
			<template #title>Certificate Status</template>
			<v-form v-model="valid" ref="form">
				<v-row>
					<v-col cols="12" lg="3">
						<v-select v-model="report_params.corporate_id" :items="corporates" item-text="name" item-value="id" label="Corporate" clearable :rules="requiredRules"></v-select>
					</v-col>

					<v-col cols="12" class="d-flex justify-end">
						<v-btn color="success" elevation="2" @click="getData" :loading="loading">
							Get Data
						</v-btn>
					</v-col>
				</v-row>
			</v-form>

			<v-data-table :headers="tableHeaders" :items="reportData.results" item-key="id" :search="search">
				<template v-slot:item.passed="{ item }">
					{{ item.passed ? 'Passed' : 'Failed' }}
				</template>
				<template v-slot:footer.prepend>
					<v-row>
						<v-col cols="auto">
							<ti-export-to-csv :json-data="reportData.results" filename="Benchmark Results"/>
						</v-col>
					</v-row>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>

import {sync} from "vuex-pathify";

export default {
	name: "CertificateProgress",
	computed: {
		corporates: sync('corporates/corporates'),
		tableHeaders() {
			if (this.reportData.results.length < 1) {
				return this.headers;
			} else {
				console.log(this.reportData.results[0]);
				return Object.keys(this.reportData.results[0]).map((key, index) => (
					{
						text: key,
						align: 'start',
						sortable: true,
						value: key,
					}
				));
			}
		},
	},
	mounted() {
		this.init()
	},
	data: () => ({
		valid: false,
		requiredRules: [
			v => !!v || 'Required',
		],
		report_params: {
			corporate_id: null,
		},
		search: '',
		loading: false,
		reportData: {
			count: 0,
			results: []
		},
		headers: [
			{
				text: 'First name',
				align: 'start',
				sortable: true,
				value: 'first_name',
			},
			{
				text: 'Last name',
				align: 'start',
				sortable: true,
				value: 'last_name',
			},
			{
				text: 'Email',
				align: 'start',
				sortable: true,
				value: 'email',
			},
		],
	}),
	methods: {

		init() {
			this.$store.set('corporates/getCorporates!', null);
		},
		getCertificates() {
			if (!this.report_params.corporate_id) return;
			this.loading = true;
			this.$api.corporates.getCertificates(this.report_params.corporate_id)
				.then(response => {
					if (response.data.length < 1) {
						this.$toast.add('No certificates found for this corporate', 'error')
					}
					this.corporate_certifications = response.data
				})
				.catch(error => this.$toast.add(error.message, 'error'))
				.finally(() => this.loading = false)
		},
		getData() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$api.reports.certificateProgress({report_params: this.report_params})
					.then(response => this.reportData = response.data)
					.catch(error => console.log(error))
					.finally(() => this.loading = false)
			}
		}
	}
}
</script>

<style lang="scss">
table.pvtTable thead tr th, table.pvtTable tbody tr th {
	background: #eee;
}

.pvtUi {
	* {
		font-family: 'roboto', sans-serif;
	}

	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;

	tr td {
		border: 1px solid #333;

		&:first-child {
			width: 300px;
		}
	}
}
</style>